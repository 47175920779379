
.detail-widget-image {
    width: 80px;
    height: auto;
}
.part-header {
    background-color: transparent;
    border-radius: 8px;
    font-size: 18px;
    margin-bottom: 10px;
    color: var(--blackColor);
    font-family: mediumFont;
}
.part-extra {
    vertical-align: bottom;
    float: right;
    font-size: 14px;
    color: var(--whiteColor);
    border-radius: 6px;
}
.part-search {
    background-color: transparent;
    font-size: 16px;
    color: var(--blackColor);
    font-family: mediumFont;
    margin-bottom: 15px;
}
.custom-text {
    padding: 5px 42px;
    height: 36px;
    line-height: 36px;
}
.list-table {
    overflow-x: auto;
}
.bg-border {
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 10px;
    margin : 10px;
}
.login-part-header-container {
    background-color: var(--blueColor);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding-bottom: 2%;
    padding-left: 4%;
    padding-right: 4%;
  }
@media screen and (max-width: 768px) {
    .part-header {
        font-size: 15px;
    }
  }
  