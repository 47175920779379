.auth-logo-widget {
  margin: auto;
  max-width: 550px;
  margin-top: 40px;
  /* padding-bottom: 50px; */
}
.auth-logo-widget-description {
  font-family: regularFont;
  font-size: 25px !important;
  color: var(--blackColor);
}
@media screen and (max-width: 1024px) {
  .auth-logo-widget {
    max-width: 450px;
    padding-bottom: 0px;
  }
  .auth-logo-widget-description {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .auth-logo-widget {
    max-width: 350px;
    padding-bottom: 0px;
  }
  .auth-logo-widget-description {
    font-size: 16px !important;
  }
}
