.text-button {
  font-size: 16px !important;
  cursor: pointer;
}
.text-button:hover {
  opacity: 0.8;
}
.text-button:active {
  opacity: 0.95;
}

@media screen and (max-width: 1024px) {
  .text-button {
      font-size: 14px !important;
  }
}
@media screen and (max-width: 768px) {
  .text-button {
      font-size: 14px !important;
  }
}
