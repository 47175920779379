.top-navbar-layout {
  color: var(--blackColor);
  height: 73px;
}
.top-navbar-header {
  padding: 30px 10px 10px 10px;
}

.top-navbar-header-image {
  max-width: 300px;
}

.menu-widget {
  position: absolute;
  right: -10px;
  margin-top:15px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  width: 180px;
  padding: 10px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
  color: var(--blackColor);
}

.menu-widget-item {
  color: var(--blackColor);
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}
.menu-widget-item-icon {
  padding-right: 10px;
}
.menu-widget-item:hover {
  color: var(--blackColor);
  opacity: 0.8;
}
.menu-widget-item:active {
  opacity: 0.95;
  color: var(--blackColor);
}

.top-setting-bg {
  width: 150px;
  padding: 10px;
  margin-right: 40px;
  background-color: var(--whiteColor);
  text-align: center;
  border-radius: 14px;
  -webkit-box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
}
.txt-top-title {
  text-align: left;
  font-size: 30px;
  font-family: mediumFont;
  padding-left: 10px;
  color: var(--blackColor);
}

.txt-top-welcome {
  font-size: 16px;
  font-family: regularFont;
  color: var(--blackColor);
}
.txt-top-name {
  font-size: 16px;
  font-family: regularFont;
  color: var(--blackColor);
  font-weight: 800;
  padding-left: 10px;
  padding-right: 20px;
  
}
.txt-top-logout {
  font-size: 16px;
  font-family: regularFont;
  padding-left: 20px;
  color: var(--blackColor);
  cursor: pointer;
}
.img-top-setting {
  cursor: pointer;
  padding: 20px;
}
.user-profile-image {
  cursor: pointer;
  width: 39px;
  height: 40px;
  border: 1px solid var(--greyColor);
  border-radius: 20%;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
  
}
@media screen and (max-width: 768px) {
  .top-setting-bg {
    width: 260px;
    background-color: var(--whiteColor);
    text-align: center;
    border-radius: 14px;
    margin-right: 0px;
    -webkit-box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  }
  .txt-top-title {
    text-align: center;
    font-size: 30px;
    font-family: mediumFont;
    margin-top: 10px;
    padding-left:20px;
  }
}

