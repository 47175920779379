.pdf-init-info-title {
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  padding-top: 40px;
}
.pdf-init-info-footer {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  padding-top: 10px;

}
.pdf-init-info-container {
  text-align: center;
}
.pdf-init-info-description {
  font-size: 18px;
  color: rgb(71, 71, 71);
  margin-bottom: 50px;

}

.pdf-init-item-header {
  font-size: 14px;
  color: rgb(90, 88, 88);
}

.pdf-init-item-info-header {
  font-size: 14px;
  color: rgb(80, 80, 80);
}


.pdf-init-info-revpan {
  margin-top: 10px;
  font-size: 24px;
  color: rgb(71, 71, 71);
}
/* comparables */
.pdf-comparables-container {
  margin-top: 5px;
}

.pdf-comparable-filter-container {
  font-size: 24px;
  color: rgb(71, 71, 71);
  padding: 20px;
}

.pdf-comparables-table {
  background-color: #f9fafb;
  width: 100%;
  border: 1px solid #d6d6d6;
  font-size: 16px !important;
}

.pdf-comparable-table-head {
  text-align: center;
  border-bottom: 1px solid #d0d0d0;
}

.pdf-comparable-table-head th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pdf-comparable-table-body tr {
  cursor: pointer;
  transition: background-color 0pdf-25s;
  height: 70px;
  border-bottom: 1px solid #d6d6d6;
}

.pdf-comparable-table-body tr:nth-child(even) {
  background-color: #f9f9f9;
}

.pdf-comparable-table-body tr:hover {
  background-color: rgb(241, 241, 241);
}

.pdf-comparable-item-container {
  background-color: white;
  text-align: center;
}

.pdf-comparable-image-container {
  position: relative;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.pdf-comparable-img {
  width: 60px;
  height: 40px;
  border-radius: 7px;
}

.pdf-comparable-distance {
  position: absolute;
  right: 5px;
  top: -7px;
  font-size: 14px !important;
  background-color: #e5e7eb;
  color: black;
  border-radius: 5px;
  padding: 2px 5px;
}
.pdf-average-value-title {
  padding-top: 5px;
  padding-left: 40px;
  font-size: 20px;
  color: rgb(71, 71, 71);
}
.pdf-average-value-value {
  font-size: 20px;
  font-weight: bold;
}
@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}
