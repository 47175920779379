.green-button {
  width: 100%;
  height: 50px;
  border-radius: 5px !important;
  font-family: mediumFont;
  font-size: 20px;
  color: var(--whiteColor);
  border: 0px solid transparent;
}

@media screen and (max-width: 1024px) {
  .green-button {
    height: 40px;
    border-radius: 5px !important;
    font-size: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media screen and (max-width: 768px) {
  .green-button {
    height: 35px;
    border-radius: 4px !important;
    font-size: 14px;
    padding-left: 6px;
    padding-right: 6px;
  }
}
