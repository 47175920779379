.input-password > .ant-input-password {
  height: 40px !important;
  border-radius: 7px !important;

  background-color: transparent !important;
  padding-left: 10px;
  padding-right: 10px;
  font-family: regularFont;
  font-size: 16px !important;
}
.input-password > * .ant-input {
  font-family: regularFont;
  font-size: 16px !important;
}
.phone-input-password-wrong {
  border: 1px solid var(--redColor) !important;
}
.phone-input-password-correct {
  border: 1px solid var(--greyColor) !important;
}

@media screen and (max-width: 1024px) {
  .input-password > .ant-input-password {
    height: 35px !important;
    border-radius: 5px !important;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .input-password > * .ant-input {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 768px) {
  .input-password > .ant-input-password {
    height: 35px !important;
    border-radius: 4px !important;
    font-size: 14px;
    padding-left: 6px;
    padding-right: 6px;
  }
  .input-password > * .ant-input {
    font-size: 14px !important;
  }
}
