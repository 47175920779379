.phone-number-input > * .PhoneInputInput {
  height: 40px !important;
  border-radius: 7px !important;
  font-family: regularFont;
  font-size: 16px;
  background-color: transparent !important;
  padding-left: 10px;
  padding-right: 10px;
}
.phone-input-wrong > .PhoneInputInput {
  border: 1px solid var(--redColor) !important;
}
.phone-input-correct > .PhoneInputInput {
  border: 1px solid var(--greyColor) !important;
}

.PhoneInputCountryIcon {
  width: 45px !important;
  height: 30px !important;
}

.email-input > .ant-input {
  height: 40px !important;
  border-radius: 7px !important;
  margin-top: 5px;
  font-family: regularFont;
  font-size: 16px;
  background-color: transparent !important;
  padding-left: 10px;
  padding-right: 10px;
}
.register-with-switcher {
  text-align: left;
  margin-bottom: 10px;
}
.register-with-switcher > * .ant-radio-wrapper {
  font-family: regularFont;
  font-size: 16px;
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .phone-number-input > * .PhoneInputInput {
    height: 35px !important;
    border-radius: 5px !important;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .PhoneInputCountryIcon {
    width: 40px !important;
    height: 28px !important;
  }

  .email-input > .ant-input {
    height: 35px !important;
    border-radius: 5px !important;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .register-with-switcher > * .ant-radio-wrapper {
    font-family: regularFont;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .phone-number-input > * .PhoneInputInput {
    height: 35px !important;
    border-radius: 4px !important;
    font-size: 14px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .PhoneInputCountryIcon {
    width: 30px !important;
    height: 20px !important;
  }

  .email-input > .ant-input {
    height: 35px !important;
    border-radius: 4px !important;
    font-size: 14px;
    padding-left: 6px;
    padding-right: 6px;
  }
  .register-with-switcher > * .ant-radio-wrapper {
    font-family: regularFont;
    font-size: 14px;
  }
}
