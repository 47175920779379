.item-multi-print-body {
  padding: 5px;
  background-color: #fafafa;
}
.ant-modal.custom-multi-print-dlg {
    width: 35% !important;
    min-width: 300px;
    top:20%;
  }
  .ant-modal.custom-multi-print-dlg .ant-modal-title {
    font-family: mediumFont;
    font-size: 18px;
  }
  .ant-modal.custom-multi-print-dlg .ant-modal-content{
    border: 15px solid var(--whiteColor);
    border-radius: 10px;
  }
  .product-image-widget {
    text-align: center;
    width: 100%;
  }
  .product-image-widget-image {
    height: 100px;
    font-size: 50px;
    opacity: 1;
    cursor: pointer;
    text-align: center;
    border-radius: 15px;
    border-color: var(--blueColor);
    border-width: 2px;
    border-style: dashed;
  }
  
  .product-image-widget-image:hover {
    opacity: 0.8;
  }
  .product-image-widget-image:active {
    opacity: 0.9;
  }
  .product-image-widget-error {
    border: dashed 2px var(--redColor);
  }
  
  .product-upload-basic-unit-add-button{
    border-radius: 7px;
    color: var(--whiteColor);
    background-color: var(--blueColor);
    font-size: 14px;
    padding: 8px 24px;
  }
  .product-upload-basic-unit-add-button:hover{
    color: #f0fbff;
  }
  .product-upload-basic-unit-add-button:active{
    color: #f0fbff;
  }
  .ant-modal.custom-multi-print-dlg .ant-modal-footer {
    border-top: none;
  }
  .ant-modal.custom-multi-print-dlg .ant-select {
    border: 1px solid var(--backgroundColor);
    border-radius: 8px;
    background: var(--backgroundColor);
    padding: 4px 0px;
  }
  .ant-modal.custom-multi-print-dlg .ant-select-focused {
    /* border: 1px solid #8bc4e8 !important; */
    box-shadow: 0 0 0 2px rgb(96 168 220 / 20%);
  }
  .ant-modal.custom-multi-print-dlg .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: var(--backgroundColor);
    padding-left: 0px;
  }
  .ant-modal.custom-multi-print-dlg .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    padding:0px;
  }
  .ant-modal.custom-multi-print-dlg .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
    padding-left: 0px;
  }
  .ant-modal.custom-multi-print-dlg .ant-select-single .ant-select-selector .ant-select-selection-search {
    left:0px;
    padding-left: 12px;
  }
  .ant-modal.custom-multi-print-dlg .ant-select-arrow {
    width: 14px;
    height: 14px;
    font-size: 14px;
  }
  .ant-modal.custom-multi-print-dlg .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-modal.custom-multi-print-dlg .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0px;
    padding-left: 12px;
  }
  .ant-modal.custom-multi-print-dlg .ant-modal-close-x {
    height: 45px;
    line-height: 45px;
  }
  .ant-modal.custom-multi-print-dlg .ant-btn {
    height: 40px;
    padding: 8px 32px;
    border-radius: 8px;
  }
  
  @media screen and (min-width: 1200px) {
    .ant-modal.custom-multi-print-dlg {
      /* width: 40% !important; */
      min-width: 580px !important;
    }
  }
  