.auth-dialog-widget {
  max-width: 420px;
  margin: auto;
  box-shadow: 0px 0px 3px var(--greyColor);
  border-radius: 10px;
  background-color: var(--whiteColor);
  padding: 10px 20px;
  margin-top: 30px;
  padding-bottom: 40px;
}

.auth-dialog-widget > .title {
  font-size: 30px;
  font-family: semiBoldFont;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1024px) {
  .auth-dialog-widget {
    max-width: 400px;
    margin-top: 20px;
  }

  .auth-dialog-widget > .title {
    font-size: 26px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 576px) {
  .auth-dialog-widget {
    max-width: 350px;
    margin-top: 0px;
  }

  .auth-dialog-widget > .title {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
