.ant-modal.custom-base-unit-dlg {
    top:25% !important;
}
.ant-modal.custom-base-unit-dlg .ant-modal-title {
    font-family: mediumFont;
    font-size: 18px;
}
.ant-modal.custom-base-unit-dlg .ant-modal-content{
    border: 15px solid var(--whiteColor);
    border-radius: 10px;
}
.ant-modal.custom-base-unit-dlg .ant-modal-header {
    border-bottom: none;
}
.ant-modal.custom-base-unit-dlg .ant-modal-footer {
    border-top: none;
}
.ant-modal.custom-base-unit-dlg .normal-input {
    border-radius: 8px;
    background: var(--backgroundColor);
    padding: 8px 12px;
}
.ant-modal.custom-base-unit-dlg .ant-btn {
    height: 40px;
    padding: 8px 32px;
    border-radius: 8px;
}
.ant-modal.custom-base-unit-dlg .ant-modal-close {
    display: none;
}
  