.parent-table {
    overflow: auto;
    white-space: nowrap;
}

.child-table {
    display: inline-table;
    padding: 10px;
}

.printing {
    visibility: hidden;
}

.printView {
    visibility: visible;
}

.printing .printView {
    /* You can have any CSS here to make the view better on print */
    position: absolute;
    top: 0;
    margin-top: -120px;
}

.printing .btn_order_normal {
    /* You can have any CSS here to make the view better on print */
    display: none;
}
