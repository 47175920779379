.landing-header {
  width: 100%;
  padding: 25px 20px;
  border-end-end-radius: 20px;
  border-end-start-radius: 20px;
  background: white;
  box-shadow: 0px 0px 10px var(--greyShadowColor);
}
.landing-app-download-image {
  max-width: 100%;
}
.landing-header-category-item{
  font-family: mediumFont;
  font-size: 17px;
  color: var(--blueColor);
}

.landing-header-title{
  font-family: mediumFont;
  font-size: 30px;
  color: var(--blueColor);
}