.one-item {
  max-width: 360px;
  border: 1px solid #D9D9D9;
}
.item-header {
  background-color: #389b3a;
  padding: 8px 8px;
  color: white;
}
.customer-name {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 100px;
  text-align: right;
  margin-bottom: 0px;
}
.item-body {
  padding: 5px;
  background-color: #fafafa;

}
.item-body-bottom {
  margin-top: 3px;
  margin-bottom: 3px;
  width: 100%;
  height: 1px;
  background-color: #bfbfbf;
}
.item-name {
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-note {
  white-space: pre-wrap;
}
.btn_order_normal {
  cursor: pointer;
  display: block;
  margin: 2px;
  margin-top: 0px;
  text-transform: uppercase;
  text-align: center;
  width: 99%;
  padding: 9px;
  border-radius: 5px;
  min-height: 40px;
  background-color: rgb(236 79 55);
  color: rgb(255, 255, 255);
  border: 1px solid #D9D9D9;
  font-size: 1em;
}

.btn_cancel_order {
  cursor: pointer;
  display: block;
  margin: 2px;
  margin-top: 0px;
  text-transform: uppercase;
  text-align: center;
  width: 99%;
  padding: 9px;
  border-radius: 5px;
  min-height: 40px;
  background-color: lightgrey;
  color: black;
  border: 1px solid #D9D9D9;
  font-size: 1em;
}

.btn-print {
  cursor: pointer;
  display: block;
  margin: 2px;
  margin-top: 0px;
  text-transform: uppercase;
  text-align: center;
  width: 99%;
  padding: 9px;
  border-radius: 5px;
  min-height: 40px;
  background-color: #60a8dc;
  color: rgb(255, 255, 255);
  border: 1px solid #D9D9D9;
  font-size: 1em;
}
.btn-multi-print {
  cursor: pointer;
  display: block;
  margin: 2px;
  margin-top: 0px;
  text-transform: uppercase;
  text-align: center;
  width: 99%;
  padding: 9px;
  border-radius: 5px;
  min-height: 40px;
  background-color: #60a8dc;
  color: rgb(255, 255, 255);
  border: 1px solid #D9D9D9;
  font-size: 1em;
}